<template>
  <div class="CouponBankList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :hasOperateColumn='false'
    >
      <template #headerSlot>
        <v-button text="系统通知规则" permission="rule" @click="directGrant"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="用户姓名" v-model="searchParams.userName"></v-input>
        <v-input label="用户手机" v-model="searchParams.mobileNum"></v-input>
        <!-- <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-input label="分期" placeholder="请输入分期" v-model="searchParams.blockName" />
        <v-input label="幢" placeholder="请输入幢" v-model="searchParams.buildingNumber" />
        <v-input label="单元" placeholder="请输入单元" v-model="searchParams.unit" />
        <v-input label="室" placeholder="请输入室" v-model="searchParams.room" />
        <v-select2 label="关联组织" v-model="searchParams.orgId" v-bind="orgParams"></v-select2> -->
        <v-select label="通知原由" v-model="searchParams.type" :options="typeOps"></v-select>
        <v-select label="是否APP通知" v-model="searchParams.appPushStatus" :options="statusOps"></v-select>
        <v-select label="是否短信通知" v-model="searchParams.msgPushStatus" :options="statusOps"></v-select>
        <v-input label="APP通知内容" v-model="searchParams.appPushContent"></v-input>
        <v-input label="短信通知内容" v-model="searchParams.msgPushContent"></v-input>
        <v-datepicker label="通知时间" type="rangedatetimer" :startTime.sync="searchParams.pushTimeStart" :endTime.sync="searchParams.pushTimeEnd"></v-datepicker>
        <!-- defaultTimeType="month"  -->
      </template>
    </list>
    <el-dialog title="系统通知规则" :visible.sync="dialogLabelVisible" width="780px">
      <div>
        <table-panel  :headers="tableHeaders" :tableData="tableData">
          <template #operateSlot="scope">
            <div class="operate">
              <v-button text="编辑通知内容" type="text" @click="edit(scope.row)"></v-button>
            </div>
          </template>
        </table-panel>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <v-button text="关闭" @click="onCancel" class="footer-btn"></v-button> -->
        <v-button text="确认" @click="onConfirm" class="footer-btn"></v-button>
      </div>
    </el-dialog>
    <el-dialog title="编辑通知内容" :visible.sync="dialogLabelVisible1" width="700px">
      <div>
        <col2-detail>
          <!-- <col2-block>
            <col2-item label="通知方式">
              <v-select v-model="informType" :options="informTypeOps" @change="selectChange"></v-select>
            </col2-item>
          </col2-block> -->
          <col2-block>
           <col2-item label="通知内容">
              <v-textarea v-model="textContent" width="400" placeholder="请输入通知信息" :maxlength="300"></v-textarea>
            </col2-item>
          </col2-block>
        </col2-detail>
      </div>
      <div slot="footer" class="dialog-footer">
        <v-button text="取消" @click="onCancel1" class="footer-btn"></v-button>
        <v-button text="确认" @click="onConfirm1" class="footer-btn"></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  infoTaskListURL,
  updateTaskStatusURL,
  updateMsgContentURL
} from './api'
import {
  statusOps,
  statusMap,
  typeOps,
  typeMap,
  informTypeOps
} from './map'
import { TablePanel, ChosenListPanel, CheckboxPlus, Col2Detail, Col2Block, Col2Item } from 'components/bussiness'
import { vCheckbox } from "components/control";
import {
  orgParams, communityParams
} from 'common/select2Params'
import { createAlinkVNode } from 'common/vdom'
export default {
  name: 'CouponBankList',
  components: {
    TablePanel,
    ChosenListPanel,
    CheckboxPlus,
    vCheckbox,
    Col2Detail,
    Col2Block,
    Col2Item
  },
  data () {
    let _this = this
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps: statusOps(1),
      typeOps: typeOps(1),
      informTypeOps: informTypeOps(),
      orgParams,
      searchParams: {
        userName: '',
        mobileNum: '',
        // communityId: '',
        // blockName: '',
        // buildingNumber: '',
        // unit: '',
        // room: '',
        // orgId: '',
        type: undefined,
        appPushStatus: undefined,
        msgPushStatus: undefined,
        appPushContent: '',
        msgPushContent: '',
        pushTimeStart: '',
        pushTimeEnd: ''
      },
      headers: [
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        // {
        //   prop: 'communityName',
        //   label: '所属项目'
        // },
        // {
        //   prop: 'houseName',
        //   label: '所在房号'
        // },
        // {
        //   prop: 'orgName',
        //   label: '关联组织'
        // },
        {
          prop: 'type',
          label: '通知原由',
          formatter: (row, prop) => {
            return row.type !== null ? typeMap[row.type] : ''
          }
        },
        {
          prop: 'appPushStatus',
          label: '是否APP通知',
          formatter: (row, prop) => {
            return row.appPushStatus !== null ? statusMap[row.appPushStatus] : ''
          }
        },
        {
          prop: 'appPushContent',
          label: 'APP通知内容'
        },
        {
          prop: 'msgPushStatus',
          label: '是否短信通知',
          formatter: (row, prop) => {
            return row.msgPushStatus !== null ? statusMap[row.msgPushStatus] : ''
          }
        },
        {
          prop: 'msgPushContent',
          label: '短信通知内容'
        },
        {
          prop: 'pushTime',
          label: '通知时间'
        }
      ],
      tableHeaders: [
        {
          label: '事件',
          prop: 'type',
          formatter: (row, prop) => {
            return row.type !== null ? typeMap[row.type] : ''
          }
        },
        {
          label: '说明',
          prop: 'description'
        },
        {
          label: 'APP通知开关',
          prop: 'appPushStatus',
          formatter: (row, prop) => {
              let h = _this.$createElement
              return h(
              "div",
              {
                style: {
                  display: "flex",
                },
              },
              [
                h("v-checkbox", {
                  style: {
                    display: "inline-flex",
                  },
                  props: {
                    value: row.appPushStatus === 1? true : false,
                  },
                  on: {
                    change: ($event) => _this.select($event, 1, row.id),
                  }
                }),
                h("span", "开启"),
              ]
            );
          }
        },
        {
          label: '短信通知开关',
          prop: 'msgPushStatus',
          formatter: (row, prop) => {
              let h = _this.$createElement
              return h(
              "div",
              {
                style: {
                  display: "flex",
                },
              },
              [
                h("v-checkbox", {
                  style: {
                    display: "inline-flex",
                  },
                  props: {
                    value: row.msgPushStatus === 1? true : false,
                  },
                  on: {
                    change: ($event) => _this.select($event, 2, row.id),
                  }
                }),
                h("span", "开启"),
              ]
            );
          }
        },
        {
          label: 'APP通知内容',
          prop: 'appContent'
        },
        {
          label: '短信通知内容',
          prop: 'msgContent'
        }
      ],
      tableData: [],
      statusList: [],
      dialogLabelVisible: false,
      dialogLabelVisible1: false,
      ruleForm: {
        grantObjType: 1,
        grantObjs: []
      },
      communityParams: communityParams,
      textContent: '',
      msgId: '',
      informType: 0,
      dialogEdit: {}
    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    edit (row) {
      console.log(row)
      this.dialogEdit = row
      this.informType = 0
      this.textContent = row.appContent
      this.msgId = row.id
      this.dialogLabelVisible1 = true
    },
    onCancel1 () {
        this.dialogLabelVisible1 = false
    },
    onConfirm1 () {
      let parameter = {
        msgId: this.msgId,
        content: this.textContent
      }
      this.$axios.post(updateMsgContentURL, this.$qs.stringify(parameter), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      }).then(res => {
        if (res.status === 100) {
          this.fetchDetail()
          this.$message.success('修改成功！')
          this.dialogLabelVisible1 = false
        }
      })
    },
    selectChange (val) {
      if (val === 0) {
        this.textContent = this.dialogEdit.appContent
        this.msgId = this.dialogEdit.appPushId
      } else {
        this.textContent = this.dialogEdit.msgContent
        this.msgId = this.dialogEdit.msgPushId
      }
    },
    select (val, type, id) {
        console.log('val, type', val, id)
         this.statusList.forEach((item, index) => {
            if (item.id === id) {
                if (type === 1) {
                  item.appPushStatus = val ? 1 : 0
                  this.tableData[index].appPushStatus = val ? 1 : 0
                } else {
                  item.msgPushStatus = val ? 1 : 0
                   this.tableData[index].msgPushStatus = val ? 1 : 0
                }
            }
        })
        console.log(this.statusList, 'this.statusList')
    },
    directGrant () {
      this.fetchDetail()
      this.dialogLabelVisible = true
    },
    onCancel () {
        this.dialogLabelVisible = false
    },
    onConfirm () {
        this.$axios.post(updateTaskStatusURL, this.statusList).then(res => {
          if (res.status === 100) {
            this.$refs.list.searchData()
            this.$message.success('修改成功！')
            this.dialogLabelVisible = false
          }
        })
    },
    async fetchDetail () {
      let { data } = await this.$axios.get(infoTaskListURL)
      this.tableData = data
      this.statusList = []
      data && data.forEach((item) => {
          this.statusList.push({
              id: item.id,
              appPushStatus: item.appPushStatus,
              msgPushStatus: item.msgPushStatus
          })
      })
    }
  }
}
</script>
<style lang="scss">
.CouponBankList-wrapper {
  .el-form-item__content {
    margin-left: 80px;
    text-align: left;
  }
  .dialog-footer {
      .footer-btn {
          margin-left: 10px;
      }
  }
}
</style>
