import { mapHelper } from 'common/utils'

// 是否短信/APP 通知
const status = [
  {
    text: '否',
    value: 0
  },
  {
    text: '是',
    value: 1
  }
]
const {
  map: statusMap,
  setOps: statusOps
} = mapHelper.setMap(status)

// 通知方式
const informType = [
  {
    text: 'APP通知',
    value: 0
  },
  {
    text: '短信通知',
    value: 1
  }
]
const {
  map: informTypeMap,
  setOps: informTypeOps
} = mapHelper.setMap(informType)

// 通知原由
const type = [
  // {
  //   text: '手动事件',
  //   value: 0
  // },
  {
    text: '注册提交',
    value: 1
  },
  {
    text: '注册审核通过',
    value: 2
  },
  {
    text: '实名认证通过',
    value: 3
  },
  {
    text: '生日祝贺',
    value: 4
  },
  {
    text: '访客通行',
    value: 5
  },
  {
    text: '活动前12小时提醒',
    value: 6
  },
  {
    text: '快递发出通知',
    value: 7
  },
  {
    text: '评价邀请',
    value: 8
  },
  {
    text: '退款成功通知',
    value: 9
  }
]
const {
  map: typeMap,
  setOps: typeOps
} = mapHelper.setMap(type)

export {
  statusMap,
  statusOps,
  typeOps,
  typeMap,
  informTypeOps,
  informTypeMap
}
