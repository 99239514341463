// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}infoPush/infoPushList`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}infoPush/infoPushExport`

// 系统通知规则列表
const infoTaskListURL = `${API_CONFIG.butlerBaseURL}infoPush/infoTaskList`

// 修改推送状态
const updateTaskStatusURL = `${API_CONFIG.butlerBaseURL}infoPush/updateTaskStatus`

// 修改推送状态
const updateMsgContentURL = `${API_CONFIG.butlerBaseURL}infoPush/updateMsgContent`

export {
  getListURL,
  exportListURL,
  infoTaskListURL,
  updateTaskStatusURL,
  updateMsgContentURL
}
